.goBack {
  font-size: "14px";
  min-height: "45px";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  cursor: pointer;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  border-radius: 50%;
  &:hover {
    box-shadow: 2px 2px 8px #84b6ee, -2px -2px 8px #8bbffa;
    -webkit-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px);
  }
}
