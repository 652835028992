//Define Colors
$white: #ffffff;
$black: #000000;
$orange: #ff4f01;
$orangeShade: #efe1d8;
$lightOrange: #ff986a;
$red: #a4243b;
$blueColor: #2769aa;


//Shadow scss
$bottomShadow: rgba(0, 0, 0, 0.45);