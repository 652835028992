.slick-slider {
  .slick-list {
    width: 100%;
    .slick-slide img {
      width: 100%;
    }
    .slick-dots li button:before {
      font-size: 35px;
      opacity: 1;
    }

    .slick-active button:before {
      color: #ed6c02 !important;
      opacity: 1;
    }

    .slick-dots {
      bottom: 10px;
    }
    .sliderSection {
      position: relative;
      .contentContainer {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 1;
        .content {
          padding-left: 95px;
          .heading {
            font-size: 4rem;
            color: #fff;
            font-weight: 600;
            letter-spacing: 0.5px;
            line-height: 1.1;
            margin-bottom: 15px;
            /*For Mobile responsive*/
            @media (max-width: 1180px) {
              font-size: 2rem;
              line-height: 1;
            }
            @media (max-width: 480px) {
              font-size: 1.5rem;
              line-height: 1.2;
            }
            /*For Mobile responsive*/
          }
          .subHeading {
            font-size: 26px;
            font-weight: 600;
            color: #eee;
            letter-spacing: 1px;
            font-family: "Fredoka", sans-serif !important;
            /*For Mobile responsive*/
            @media (max-width: 1180px) {
              font-size: 18px;
            }
            @media (max-width: 480px) {
              font-size: 16px;
            }
            /*For Mobile responsive*/
          }
          &.text1,
          &.text2,
          &.text3,
          &.text4 {
            width: 60%;
            /*For Mobile responsive*/
            @media (max-width: 1180px) {
              width: 90%;
              padding-left: 0;
              margin: 0 auto;
              text-align: center;
            }
            /*For Mobile responsive*/
          }
        }
      }
      &::before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1;
        position: absolute;
        transition: all 0.8s ease;
      }
    }
  }
  .slick-prev {
    left: 10px;
    background-color: red;
    z-index: 1;
  }
  .slick-next {
    right: 10px;
  }
  .slick-next,
  .slick-prev {
    width: 42px;
    height: 42px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.8);
    &::before {
      font-size: 17px;
      line-height: 0.6;
    }
  }
}
