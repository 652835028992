.attendanceSec {
  .stdRecord {
    .monthTitle {
      min-width: 150px;
      text-align: center;
    }
    .disable {
      visibility: hidden;
    }
    button.iconOutline {
      &.monthIcon {
        min-width: 45px;
        height: 45px;
        border-radius: 100%;
        padding: 0;
        &:active,
        &:focus,
        &:hover {
          box-shadow: 0px 4px 6px -2px;
        }
        svg {
          font-size: 20px;
        }
      }
    }
  }
  .attendanceUpload {
    .attendLabel {
      font-size: 14px;
      p {
        font-size: 14px;
      }
      cursor: pointer;
    }
  }
}

.MuiPickersCalendarHeader-labelContainer {
  pointer-events: none;
}

/*For Mobile responsive*/
@media (max-width: 768px) {
  .stdRecord .monthTitle {
    font-size: 1rem;
  }
  .customFullNameClass > .MuiDataGrid-withBorderColor:nth-child(2) {
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 22;
  }
}
@media (max-width: 500px) {
  .additional-card-head.MuiBox-root.css-3cn5wv {
    padding-top: 10px;
    border-top: 1px solid #ccc;
    width: 100%;
    margin-top: 5px;
  }
}

@media (max-width: 440px) {
  body .customCard .title .additional-card-head {
    gap: 4px;
  }
}
