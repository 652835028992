.dilogTbl {
  td p {
    margin: 0;
  }
  .insideRow {
    td,
    th {
      padding: 0 !important;
      border: 0;
      &:first-child {
        margin-left: 20px;
        min-width: 240px;
      }
      &:nth-child(2) {
        min-width: 240px;
      }
    }
  }
}
.feeInfo {
  .customTable {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    th {
      font-weight: bold;
      background: #2769aa;
      color: #fff;
    }
    td {
      vertical-align: top;
    }
    .amountSec {
      div {
        display: flex;
        justify-content: space-between;
        b {
          min-width: 120px;
          display: inline-block;
        }
      }
      .totalAmt {
        border-top: 1px solid;
        margin-top: 5px;
        padding-top: 6px;
      }
    }
  }
  .status {
    font-weight: 600;
    border: 1px solid;
    border-radius: 30px;
    padding: 8px 15px;
    max-width: 120px;
    text-align: center;
    &::before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 100%;
      display: inline-block;
    }
  }
}

//Color theme scss for light
.App.light {
  .status {
    min-width: 120px;
    &.pending {
      color: #df9100;
    }
    &.success {
      color: #026202;
    }
    &.failed {
      color: #fff;
      background: #fc3030;
    }
  }
}
//Color theme scss for dark
.App.dark {
  .status {
    &.pending {
      color: #ffbe44;
    }
    &.success {
      color: #a8ffa8;
    }
    &.failed {
      color: #fe6d6d;
    }
  }
}
