.quizItem{
    font-family: "Lato",sans-serif;
    color: #444;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.65rem;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
.AssessmentHeading{
    font-family: "Helvetica";
    padding: 0 25px 0 25px;
}