.formulation {
  border-color: #cfcfcf !important;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
}

.formulation .img-list ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.formulation .img-list ul li {
  margin-right: 5px;
}

.formCheckbox {
  width: 40px;
}

.formulation h6 {
  line-height: 1.5;
  margin-bottom: 15px;
}

.btn-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.btn-wrap button:hover {
  background-color: #a4243b !important;
  border-color: #a4243b !important;
}

.assessmentAttempt-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}

.assesOuter {
  display: flex;
}

.assessmentQuestions-inner {
  padding: 6px !important;
  border: 1px solid #aaaaaa;
}

.assessmentQuestions h3 {
  color: #fff;
  font-size: 18px;
  padding: 6px;
}

.assessmentQuestions .Flagged,
.assesmentSingQuest .Flagged {
  font-size: 1px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 101px;
  height: 110px;
  background-repeat: no-repeat;
}

.assessmentQuest {
  text-align: center !important;
  font-size: 24px;
  color: #606060;
  width: 80%;
  margin: auto;
}
.assessmentQuest p {
  text-align: center !important;
}

.quizAttemptReview h3 {
  margin: 0;
  font-size: 1.6rem;
  margin-bottom: 20px;
  margin-top: 32px;
  line-height: 1.167;
  letter-spacing: 0em;
}

.assessmentQuest .quizQuestInput,
.reviewQuestionTxt .quizQuestInput {
  width: 100px;
  height: 35px;
  background-color: #ffffff;
  border: 1px solid #9e9e9e;
  margin: 20px 10px;
  padding-left: 10px;
  text-align: center;
  border-radius: 8px;
}

.quizNextBack {
	display: flex;
	justify-content: center;
	bottom: 0;
	position: absolute;
	margin: 0 auto 20px;
	width: 100%;
}

.quizNextBack .noOfQuest {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  font-size: 18px;
  font-weight: bold;
  color: #606060;
}

.radioOptions {
  justify-content: center;
  color: #2769aa;
  margin-bottom: 50px;
}

.radioOptions .MuiFormControlLabel-label {
  font-size: 24px;
}

.assessmentQuestions .Flagged input#myCheckbox1,
.assesmentSingQuest .Flagged input#myCheckbox1 {
  width: 39px;
  height: 39px;
  position: absolute;
  top: 7px;
  left: 10px;
}
.assessmentQuestions .Flagged input#myCheckbox1:before {
  content: "";
  border-left: 32px solid #060606;
  border-right: 0 solid transparent;
  border-bottom: 0 solid transparent;
}

input#myCheckbox1 ~ label {
  background-color: #7c7c7c;
}

input#myCheckbox1:checked ~ label {
  background-color: #204bbc;
}

.assessmentQuestions .Flagged label,
.assesmentSingQuest .Flagged label {
  display: block;
  position: absolute;
  cursor: pointer;
  top: 11px;
  left: 14px;
}

.Flagged svg {
  font-size: 40px;
}

/* .assessmentQuestions .Flagged label:before,
.assesmentSingQuest .Flagged label:before {
    display: block;
    color: #adb1b5;
    content: "\2713";
    position: absolute;
    left: -5px;
    font-size: 20px;
    font-weight: bold;
    top: -2px;
} */

.Flagged.flagNotChecked {
  color: #cecece;
  background-image: url("http://test-lms.ratnasagar.com/lms-assets/images/flagInActiveBg.jpg");
}
.Flagged.flagChecked {
  color: #fff;
  background-image: url("http://test-lms.ratnasagar.com/lms-assets/images/flagActiveBg.jpg");
}

.assessmentQuestions .Flagged :checked + label,
.assesmentSingQuest .Flagged :checked + label {
  border-color: #ddd;
}

/* .assessmentQuestions .Flagged :checked+label:before,
.assesmentSingQuest .Flagged :checked+label:before {
    color: #6ce77c;
    
} */

.assessmentQuestions .Flagged :checked + label span:before,
.assesmentSingQuest .Flagged :checked + label span:before {
  font-size: 18px;
  content: "Remove mark";
}

.assessmentQuestions .Flagged :checked + label span,
.assesmentSingQuest .Flagged :checked + label span {
  font-size: 0;
}

.assesmentSingQuest-inner {
  padding: 10px !important;
  border: 1px solid #aaaaaa;
}

.assesmentSingQuest-inner h3 {
  color: #fff;
  font-size: 22px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
}

.questionButtons {
  margin-top: 20px;
  justify-content: space-between;
}


.Quiznavigation-inner h3 {
  font-size: 22px;
  font-weight: bold;
  margin: 10px;
  display: flex;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.Quiznavigation-inner h3 span {
  margin-left: 15px;
}

.roudned_box_assessmentNav {
  border-radius: 5px 0px 20px 20px / 0px 0px 20px 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: sticky;
  top: 87px;
}

.quizReviewNavigation {
  position: sticky;
  top: 0px;
}

.roudned_box_assessmentNav h4 {
  font-weight: 400;
  font-size: 1.5rem;
  padding-bottom: 10px;
  padding-top: 20px;
}

.innerBoxF {
  pointer-events: none;
}

.roudned_box_assessment_review {
  border-radius: 5px 0px 20px 20px / 0px 0px 20px 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  min-height: 100px;
  margin-bottom: 20px;
}

.questforReview {
  padding: 0 !important;
}

.questforReview h4 {
  font-size: 1.25rem;
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.reviewQuestionTxt {
  font-size: 20px;
  color: #555555;
  padding: 0 10px;
  font-weight: 500;
}

.reviewQuestionTxt p {
  margin: 0;
}
.questforReview label {
  padding-left: 20px;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-weight: bold;
  border-top: 1px dotted #9f9e9e;
  padding: 8px;
}
.reviewButton:hover p {
  color: #fff;
}
.formsBtns {
  font-size: 18px !important;
  padding: 8px 13px !important;
  min-width: 130px !important;
}
.assesmentQuestWrapper {
    min-height: 350px;
}