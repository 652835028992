.iwt-icon-wrap{
    margin-right: 0px;
    border-radius: 12px;
    display: flex;
    padding: 5px;
    
}

.iwt-gray-bg-shadow{
    margin-right: 7px !important;
    background: #9393932b;
    box-shadow: 1px 2px 4px 0px #c1c1c1;   
}

.secondText{
    color: #505050;
    font-weight: normal;
}