.breadcrumb {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  li {
    margin-right: 5px;
    &::before {
      content: ">";
      margin-right: 5px;
    }
    &:first-child::before {
      display: none;
    }
    a {
      text-decoration: none;
      color: #545454;
      font-weight: 600;
      font-size: small;
    }
    &:last-child {
      a {
        color: #ff5307;
      }
    }
  }
}
