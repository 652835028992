
.calendarEventContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;
}

.eventWrapper .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* color: #fff !important; */
    font-size: 1.281rem;
    margin: 0;
    line-height: 1.2;
    padding-left: 20px;
    margin-bottom: 20px;
}

.textBox {
    padding-left: 15px;
    padding-right: 15px;
}

.eventWrapper .title h3 {
    font-size: 18px;
}

.eventFooter{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    padding: 12px 0;
    padding-right: 20px;
}
.eventFooter button {
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
}

.eventFooter button:hover {
    /* background-color: #FFBA49 !important; */
}

.date{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.userName{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.description{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.popupbox {
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
}
