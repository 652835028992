.createEventDialog {
  .MuiDialog-paper {
    max-width: 700px;
    .MuiDialogContent-root {
      padding: 0;
      form {
        .eventcontent {
          padding: 20px;
          .MuiGrid-root {
            .MuiFormControl-root {
              width: 100%;
            }
          }
          @media only screen and (max-width: 420px) {
            .MuiGrid-item {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
          .MuiStack-root {
            padding-top: 0;
          }
        }
      }
      .MuiDialogActions-root {
        margin-top: 10px;
        border-top: 1px solid #ebebeb;
      }
      .customDate {
        .MuiStack-root {
          overflow: visible;
        }
      }
    }
    .customTxt {
      textarea {
        height: 80px !important;
        overflow: auto !important;
      }
    }
  }
}
