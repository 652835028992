.feature-card {
  background-color: #fff;
  padding: 40px 20px 40px 20px;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.12);
  text-align: center;
  min-height: 265px;
  transition: all 0.3s ease-in-out;
  height: 100%;
  &:hover {
    transform: translateY(-5px);
  }
  img {
    max-height: 50px;
    margin-bottom: 15px;
    @media screen and (max-width: 480px) {
      max-height: 80px;
      margin-bottom: 0px;
    }
  }
  .feature-heading {
    margin-bottom: 10px;
  }
  .feature-content {
    color: #676767;
    font-weight: 400;
  }
}
