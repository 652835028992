.Quiznavigation-wrap h4 {
    padding: 10px;
    background-repeat: repeat-x;
    font-size: 20px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}



.Quiznavigation-box {
    box-shadow: none !important;
    padding: 0.7rem 0.9rem 1.1rem 0.9rem;
}


.Quiznavigation-box {
    box-shadow: none !important;
    padding: 0.7rem 0.9rem 1.1rem 0.9rem;
}

.Quiznavigation-wrap h4 span {
    position: relative;
    padding-left: 10px;
}

.Quiznavigation-wrap h4 svg {
    color: #47a025;
    margin-right: 10px;
    font-size: 20px;
}

.Quiznavigation-wrap h4 span:before {
    content: "";
    border-left: dotted 1px #cfcfcf;
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
}

.quizNavNumber a {
    text-decoration: none;
    color: inherit;
}

.quizNavNumber {
    height: 40px;
    width: 40px !important;
    border-radius: 25%;
    margin: 0 6px 12px 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: monospace;
    font-size: 17px;
    cursor: pointer;
    border: 0px;
   font-weight: 600;
    transition: all .1s;
    -webkit-box-shadow: inset 0px 0px 1px 2px #ccc;
    -moz-box-shadow: inset 0px 0px 1px 2px #ccc;
    box-shadow: inset 0px 0px 1px 2px #ccc;
    background-color: aliceblue;

   

    &.isAttempted{
        background-color: #b4f69d;
        color: #1e7200;
    }

    &.isIncorrect{
        background-color: #f7a2a2;
    }
    &.activeQuestion {
        background-color: #0a2d8c;
        color: #fff;
        -webkit-box-shadow: 0px 0px 6px 1px rgba(84, 84, 84, 0.5);
        -moz-box-shadow: 0px 0px 6px 1px rgba(84, 84, 84, 0.5);
        box-shadow: 0px 0px 6px 1px rgba(84, 84, 84, 0.5);
        transform: scale(1.2);
        &:hover{
            background-color: rgba(10, 45, 140, 0.795);
        }
    }

    &.isFlaged{
        position: relative;
        overflow: hidden;
        &::after {
            content:" ";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 0;
            border-color: transparent #ff2a00 transparent transparent;
            right: 0px;
            top: 0px;
            position: absolute;
            border-radius: 25%;
        }
    }

    .attempted {
        background-color: #47a025;
    }

    .notAttempted {
        background-color: #fff;

        svg {
            color: #a4243b;
        }
    }
}

.assesmentSingQuest {
    min-height: 250px;
}

.quizNavNumber div {
    border: 0;
    margin-top: 25px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
}