.doubtWrapper {
  .doubtSec {
    padding: 20px 30px;
    box-shadow: 0 1px 15px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin-bottom: 20px;
    .userSec {
      display: flex;
      align-items: center;
      gap: 10px;
      .userImg {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        border: 1px solid;
        overflow: hidden;
        img {
          max-width: 100%;
          margin: 0 auto;
        }
      }
      .userInfo {
        display: flex;
        flex-direction: column;
        small {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .footerArea {
      border-top: 1px solid #ddd;
      margin-top: 20px;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .footerAction {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
      }
    }
  }
}
