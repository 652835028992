.page404-main {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 60px;
  z-index: 9;
  position: relative;
  .errorImg {
    max-width: 550px;
  }
  .page404Content {
    display: flex;
    flex-direction: column;
    .btn {
      padding: 10px 20px;
      border: 1px solid;
      width: 190px;
      margin: 0 auto;
      border-radius: 45px;
      a {
        text-decoration: none;
        font-weight: 400;
        font-size: 20px;
        color: #fff;
      }
    }
  }
}
