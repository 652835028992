.customAccordion {
  .accordionSumm {
    background-color: #d4def7;
   
  }
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin: 6px 0;
     &.Mui-expanded{
      margin: 10px 0;
    }
  }
  .accordionDetail{
    padding-top: 20px;
    &.mainAccordion {
      .activityContent {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: 10px;
        @media (min-width: 768px) {
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
            &:has(.assetsBoxes:nth-child(1):nth-last-child(1)){
              grid-template-columns: repeat(auto-fit, minmax(220px, max-content));
            }
          }
        .assetsBoxes {
          // width: calc(100% / 4 + -20px);
          // min-width: 180px;
          &.assetContent{
            cursor: no-drop;
          }
          .overlayBox .dataInfo {
            font-size: 12px;
            position: absolute;
            left: 10px;
            bottom: 5px;
            color: #fff;
            opacity: 0;
            transition: opacity 0.3s ease;
            letter-spacing: 0.5px;
          }
          &:hover .dataInfo {
            opacity: 1;
          }
        }
      }
    
    }
   
  }
  
}
