table {
  .timeTblHead {
    tr {
      .MuiTableCell-root.MuiTableCell-head {
        padding: 5px;
        width: 150px;
      }
    }
  }
  .customTr {
    padding: 5px;
    width: 180px;
    .MuiInputBase-root {
      td {
        padding: 10px;
        input {
          padding: 10px 10px 4px !important;
        }
        
      }
    }
  }
  .recessTr{
    width: 140px;
  }
}

.btnsArea {
  margin-bottom: 10px;
  h6 {
    font-size: 16px;
  }
  button {
    border: 1px solid;
    width: 200px;
    margin-right: 5px;
    justify-content: flex-start;
    padding: 0;
    .iconSec {
      width: 35px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }
  }
}
