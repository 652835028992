.annualPlanner {
  .title {
    font-size: 24px;
    font-weight: 600;
    font-family: "Poppins", sans-serif !important;
  }
  .eventPlanner {
    .calendarHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.examsheetCard {
  .recentList {
    padding: 0 !important;
  }
}
