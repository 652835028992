#loader-1 {
  margin-top: calc(50vh - 200px);
  #loader {
    position: relative;
    left: 50%;
    top: 50%;
    height: 15vw;
    width: 15vw;
    margin: -10vw 0 0 -10vw;
    border: 3px solid transparent;
    border-top-color: #ff8080;
    border-bottom-color: #ff8080;
    border-radius: 50%;
    z-index: 1;
    -webkit-animation: 2s linear infinite spin;
    -moz-animation: 2s linear infinite spin;
    -o-animation: 2s linear infinite spin;
    animation: 2s linear infinite spin;
    :before {
      content: "";
      position: absolute;
      top: 5%;
      bottom: 5%;
      left: 5%;
      right: 5%;
      border: 3px solid transparent;
      z-index: 1;
      border-top-color: #88c8ed;
      border-radius: 50%;
      -webkit-animation: 3s linear infinite spin;
      -moz-animation: 3s linear infinite spin;
      -o-animation: 3s linear infinite spin;
      animation: 3s linear infinite spin;
    }
    :after {
      content: "";
      position: absolute;
      top: 10%;
      bottom: 10%;
      left: 10%;
      right: 10%;
      border: 3px solid transparent;
      border-top-color: #dfe159;
      z-index: 2;
      border-radius: 50%;
      -webkit-animation: 1.5s linear infinite spin;
      -moz-animation: 1.5s linear infinite spin;
      -o-animation: 1.5s linear infinite spin;
      animation: 1.5s linear infinite spin;
    }
  }
}

#inner {
  width: 60%;
  height: 60%;
  // border: 2px solid #00ffcd;
  border-radius: 50%;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
