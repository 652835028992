.attemptCard {
  .customCardContent {
    .attemptUl {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap:20px;
      .attemptLi {
        max-width: 193px;
        padding:0;
        a{
          display: inline-block;
          width: 100%;
          .attemptLiCard{
            padding: 10px;
          }
        }
      }
    }
  }
}
