.PasswordDetails{
    padding: 30px;
    max-width: 375px;
    margin: 0 auto;
    input{
        padding: 14px;
    }
    .passwordFields{
        margin-bottom: 15px;
    }
    button{
        background-color: #ed6c02;
        color: #fff;
        padding: 10px;
    }
    .homeLink{
        text-decoration: none;
        color: #ed6c02;
        font-weight: 600;
    }
}