.logsTable{
    .MuiDataGrid-row{
        max-height: 122.3px !important;
        min-height: 122.3px !important;
        .MuiDataGrid-cell{
            max-height: none !important;
            white-space: wrap !important;
            overflow: auto !important;
            align-items: self-start;
        }
    }
}

