$boxShadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
.eventListData {
  margin: 5px 0 !important;
  box-shadow: 2px 1px 10px 0px rgba(0, 0, 0, 0.19);
  padding: 10px;
  border-radius: 10px;
  .eventLists {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*For Mobile responsive*/
    @media (max-width: 481px) {
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
      font-size: 10px;
    }
    /*For Mobile responsive*/
  }
}
.calendar-container {
  button {
    &:hover {
      background-color: #ffb703;
      /*For Mobile responsive*/
      @media (max-width: 940px) {
        background-color: #2769aa;
      }
      /*For Mobile responsive*/
    }
  }

  .attenMarkerText {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 1;
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border-color: transparent;
      border-style: solid;
      border-radius: 0px 40px 0px 0px;
    }
  }
  .week-cont {
    justify-content: flex-end;
    grid-gap: 12px;
    margin-bottom: 10px;
    flex-wrap: nowrap;
    /*For Mobile responsive*/
    @media (max-width: 580px) {
      grid-gap: 2px;
      margin-bottom: 10px;
    }
    /*For Mobile responsive*/
    &:last-of-type {
      justify-content: flex-start;
    }

    .activeDays-cont {
      // border-color: #2c5176 !important;
      .dateNum,
      .dateWeek {
        color: #fff;
      }
    }
  }
  .days-cont {
    position: relative;
    max-width: calc(100% / 7 - 10px);
    height: 90px;
    width: 100%;
    /*For Mobile responsive*/
    @media (max-width: 580px) {
      max-width: calc(100% / 7 - 1.5px);
    }
    .calendarCell {
      width: 100%;
      height: 100%;
      border: 0 !important;
      // box-shadow: $boxShadow;
      position: relative;

      @media screen and (max-width: 586px) {
        padding-left: 2px;
        padding-right: 2px;
      }
      &:hover {
        background-color: #2769aa !important;
      }
    }
  }
  .dateNum {
    font-size: 2rem;
    display: block;
    // text-shadow: 2px 2px 1px #000;
    line-height: 2.3rem;
  }
  .dateWeek {
    display: block;
    line-height: normal;
    font-size: 21px;
    line-height: 28px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
    @media screen and (max-width: 580px) {
      font-size: 8px;
      line-height: 14px;
    }
  }
  .calendarDecorative {
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    width: 30px;
    height: 16px;
  }
  .eventsButton {
    padding: 2px 4px !important;
    font-weight: 600 !important;
    text-align: left !important;
    margin: 2px;
    width: 80px;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 85%;
    /*For Mobile responsive*/
    @media (max-width: 1024px) {
      max-width: 70px;
    }
    /*For Mobile responsive*/
  }
  .calSepEvents {
    position: absolute;
    top: 0;
    right: 0;
    /*For Mobile responsive*/
    @media (max-width: 980px) {
      background: #2769aa;
      color: #fff;
      border-radius: 50%;
      width: 25px;
      padding: 2px 0;
      top: 1px;
      right: 2px;
      box-shadow: 0px -1px 6px 1px;
    }
    @media (max-width: 580px) {
      padding: 0;
      top: -11px;
      right: 0;
      font-size: 10px;
      text-align: center;
      left: 0;
      margin: 0 auto;
      width: 20px;
      box-shadow: 0px -1px 2px 1px;
    }
    /*For Mobile responsive*/

    .eventlist {
      margin: 0;
      li {
        list-style: none;
        position: relative;
        text-align: left;
        z-index: 1;
        line-height: 21px;
        /*For Mobile responsive*/
        @media (max-width: 980px) {
          text-align: center;
        }
        /*For Mobile responsive*/
        button {
          line-height: normal !important;
          &.MuiButton-containedCategoryEventBtn {
            background-color: #FFFA94;
            color: #000
          }
          p {
            max-width: 80px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  h3 {
    padding: 14px;
    display: inline-block;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    margin-top: 0px;
    font-weight: 600 !important;
    border-radius: 4px;
    // position: absolute;
    // right: -21px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 35px;
    padding-top: 6px;
    font-size: 20px !important;
    box-shadow: $boxShadow;

    svg {
      position: relative;
      top: 5px;
    }
    span {
      padding-left: 5px;
    }
  }

  .eventListWrapper {
    display: flex;
    width: 100%;
    text-align: left;
    margin-top: 0;
  }
  .calendarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 1.6rem 0;
    padding: 2px 0;
    font-size: 1.5rem;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .arrow {
    //width: 110px;
    font-weight: bolder !important;
    /*For Mobile responsive*/
    @media (max-width: 580px) {
      width: 50px;
      min-width: 50px;
      .css-1gnd1fd-MuiButton-endIcon,
      .css-gcc2o7-MuiButton-startIcon {
        margin-right: 0;
        margin-left: 0;
      }
    }
    /*For Mobile responsive*/
  }
  .arrowIcon {
    font-size: 12px !important;
  }
  .actBopen {
    padding-left: 12px !important;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 8px;
      height: 8px;
      background-color: rgb(84, 255, 84);
      border-radius: 50%;
    }
  }
  .actBclose {
    padding-left: 12px !important;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 8px;
      height: 8px;
      background-color: red;
      border-radius: 50%;
    }
  }
  .actB {
    padding-left: 12px !important;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 8px;
      height: 8px;
      background-color: orange;
      border-radius: 50%;
    }
  }

  .calHaveEvents {
    display: none;
  }
}

.calendarSec {
  text-decoration: none;
  color: #000;
  .tabCalendar {
    margin: 25px 0 10px;
    border-radius: 20px;
    border: 2px solid #edf1ff;
    padding: 10px 20px;
    background: linear-gradient(47deg, #bdd1ef 0%, #bdc7e6 40%, #f4f7ff 100%);
    position: relative;
    text-decoration: none;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      width: 200px;
      height: 200px;
      border-radius: 100%;
      left: -70px;
      top: 0;
      transform: rotate(140deg);
      background: linear-gradient(
        56deg,
        rgba(192, 203, 240, 1) 0%,
        rgba(208, 218, 245, 1) 50%,
        rgba(229, 235, 254, 1) 100%
      );
    }
    &:after {
      content: "";
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      left: 60px;
      bottom: -30px;
      transform: rotate(-10deg);
      background: linear-gradient(
        56deg,
        rgba(192, 203, 240, 1) 0%,
        rgba(208, 218, 245, 1) 50%,
        rgba(229, 235, 254, 1) 100%
      );
    }
    .calendarContent {
      z-index: 1;
      position: relative;
      .day {
        font-weight: 600;
      }
      .date {
        margin-bottom: 20px;
      }
      .data {
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-left-width: 5px;
        border-radius: 10px;
        margin-bottom: 20px;
      }
    }
  }
}

.dark {
  .calendarSec {
    color: #ebebeb;
    .tabCalendar {
      border: 2px solid #273e56;
      background: linear-gradient(47deg, #3a4a5c 0%, #1a2b3d 40%, #3c4c5d 100%);
      color: #fff;
      &:before {
        background: linear-gradient(
          56deg,
          rgb(51 73 96) 0%,
          rgb(62 81 100) 50%,
          rgb(24 35 47) 100%
        );
      }
      &:after {
        background: linear-gradient(
          56deg,
          rgb(51 73 96) 0%,
          rgb(62 81 100) 50%,
          rgb(24 35 47) 100%
        );
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .calendar-container {
    .week-cont {
      .days-cont {
        height: 80px;
        .eventListWrapper {
          margin-top: 0;
        }
      }
      .dateNum {
        font-size: 1.5rem;
        line-height: 1.8rem;
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .calendar-container {
    .week-cont {
      .days-cont {
        height: 55px;

        .eventListWrapper {
          text-align: center;
          margin-top: 0;
          justify-content: center;
        }
      }
      .dateNum {
        font-size: 0.8rem;
        line-height: 1.2rem;
      }
    }
  }
}
@media only screen and (min-width: 1099px) {
  .webCalendar {
    display: block;
  }
}
@media only screen and (max-width: 1099px) {
  .calHaveEvents {
    position: absolute;
    background-color: #2769aa;
    color: #fff;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    line-height: 2;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eventlist li button {
    /* color: #fff !important; */
  }
  .days-cont:hover .calSepEvents {
    display: block;
  }

  .dateNum {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 1098px) {
  .tabCalendar {
    display: block;
  }
  .webCalendar {
    display: none;
  }
  .dateWeek {
    font-size: 14px;
  }
  .week-cont div button {
    min-width: auto !important;
    padding: 5px;
  }
  .calendarDecorative {
    display: none;
  }

  .calSepEvents {
    /* right: -110px; */
  }

  .calendarHeader h4 {
    font-size: 1.125rem !important;
    text-align: center;
  }
}

@media only screen and (max-width: 985px) {
  // .calHaveEvents {
  //   right: 0;
  //   top: -1px;
  //   z-index: 2;
  //   display: flex !important;
  // }
}
@media only screen and (max-width: 580px) {
  .dateNum {
    font-size: 1.5rem;
  }

  .calSepEvents {
    right: -110px;
  }

  .week-cont {
    grid-gap: 8px;
  }

  .calHaveEvents {
    width: 18px;
    height: 18px;
    left: 0;
    top: -9px;
    line-height: 1.5;
    font-size: 10px;
    right: 0;
    margin: 0 auto;
  }

  .calendarHeader h4 {
    font-size: 1rem !important;
  }
}
.light {
  .calendarCell:hover {
    border-radius: 0;
    .dateNum,
    .dateWeek {
      color: #fff;
    }
  }
}
.dark {
  .days-cont:hover {
    background-color: #2c5176;
    button {
      color: #fff;
    }
  }
  .activeDays-cont {
    border: 2px solid #2c5176;
    .dateNum,
    .dateWeek {
      color: #2c5176 !important;
    }
  }
  .calendarCell {
    .dateNum,
    .dateWeek {
      color: #fff;
    }
    &:hover {
      border-radius: 0;
    }
  }
}
