.certificateCard {
  box-shadow: none !important;
  border: 1px solid #ddd;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
  .certificateContent {
    padding: 0;
    img {
      border-bottom: 1px solid #ddd;
      max-height: 250px;
      min-width: 100%;
      min-height: 250px;
    }
  }
}
