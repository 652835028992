/*For Mobile responsive*/
$small-down: 940px;
/*For Mobile responsive*/

.homeWrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Fredoka", sans-serif;
  }
  .spacing {
    padding: 60px 0 60px 0;
  }
  .title {
    font-family: "Fredoka", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    /*For Mobile responsive*/
    @media (max-width: $small-down) {
      font-size: 2.2rem;
      margin-bottom: 10px;
    }
    /*For Mobile responsive*/
  }
  .subTitle {
    font-size: 28px;
    margin-bottom: 20px;
    font-family: "Fredoka", sans-serif;
    font-weight: 500;
    /*For Mobile responsive*/
    @media (max-width: $small-down) {
      font-size: 24px;
    }
    /*For Mobile responsive*/
  }
  .OurVision {
    .visionSec {
      position: relative;
      display: grid;
      justify-content: end;
      .visionImgSec img {
        width: 100%;
        position: relative;
        @media only screen and (min-device-width: 767px) and (max-device-width: 900px) {
          max-width: 75%;
        }
      }
    }
  }
  .countSec {
    background-color: #9c44e1;
    color: #ffffff;
    padding: 80px 0;
    /*For Mobile responsive*/
    @media (max-width: $small-down) {
      padding: 60px 0;
    }
    /*For Mobile responsive*/
    .numberSec {
      font-size: 52px;
      font-weight: 600;
      font-family: "Fredoka", sans-serif !important;
      /*For Mobile responsive*/
      @media (max-width: $small-down) {
        font-size: 44px;
      }
      /*For Mobile responsive*/
    }
    .textSec {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .aimSec {
    .feature-card {
      min-height: 320px;
    }
  }
}
