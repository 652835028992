.teacherTimeTblWrapper {
  .teacherTimeTblInfo {
    width: 210px;
  }
  .weekBox {
    padding: 0px;
    max-width: 130px;
    .subjTitle {
      font-size: 14px;
      font-weight: 600;
    }
    .subTitle {
      font-size: 12px;
    }
    &.yellowShade {
      background-color: #fff1db;
    }
    &.blueShade {
      background-color: #eef2ff;
      border-radius: 10px;
      padding: 10px;
    }
  }
}

/*For Mobile responsive*/
@media screen and (max-width: 680px) {
  .admin-timetable-top-bar .MuiBox-root.css-j0ozid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  button.deletedaysbtn,
  button.adddaysbtn {
    padding-left: 0;
    padding-right: 0;
  }
}
