.noteHead {
  width: 100%;
  label.MuiInputLabel-animated{
    color: #fff;
  }
  input {
    color: #fff;
    &:disabled {
      color: #fff;
      -webkit-text-fill-color: rgb(255 255 255);
      & + fieldset {
        border: 0;
      }
    }
  }
  fieldset {
    border: 1px solid #fff;
    legend {
      font-size: 24px !important;
    }
  }
}
.noteContent{
    .MuiInputBase-root{
        .Mui-disabled{
            -webkit-text-fill-color: #000 !important;
            & + fieldset{
                border:0;
            }
        }
    }
}
.light ~ .Mui-disabled{
    -webkit-text-fill-color:#000 !important;
}