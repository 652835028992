@mixin icons {
  cursor: pointer;
  position: absolute;

  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setCard {
  .title {
    justify-content: space-between !important;
  }
  .settingCard {
    .cardContent {
      .componentArea {
        position: relative;
        box-shadow: 0px 4px 11px 6px rgba(0, 0, 0, 0.12) inset;
        padding: 10px;
        border-radius: 10px;
        .boxInfo {
          display: flex;
            align-items: center;
            justify-content: space-between;
          .boxIcons {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        .icons {
          @include icons;
          right: 10px;
          top: 20px;
          svg {
            font-size: 20px;
            color: #fff;
          }
        }
      }
      .roleBtns {
        position: relative;
        .icons {
          @include icons;
          top: 35px;
          right: 0px;
          svg {
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }
  }
}
