:root {
  --h3: 35px;
  --h4: 25px;
  --headingGray: #333;
  --greenColor: #47a025;
  --calendarGray: #5b656f;
  --maroonColor: #88403b;
  --redColor: #a4243b;
  --orangeColor: #f60;
  --maroonColor: #88403b;
  --darkTheme: #1a2b3d;
}

body {
  overflow: auto;
  padding: 0 !important;
}

* {
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: "Poppins", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fredoka", sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.appContent {
  position: relative;
  z-index: 9;
}

.switchCircle span {
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.App.dark{
  background-color: #1a2b3d;
}
.App.light{
  background-color: #fffaf5;
}
.switchButInner {
  max-width: 1536px;
  margin: auto;
  width: 96%;
}
.languageDD .MuiSelect-outlined {
  padding: 4.5px 3px !important;
}

.languageDD .css-1n8xjfr-MuiFormControl-root {
  margin: 0;
}

.flimg {
  margin-left: 8px !important;
}

.flli {
  padding-left: 7px !important;
  justify-content: center !important;
  align-items: center !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 850px) {
  .headAction {
    right: 160px;
  }
}

@media only screen and (max-width: 750px) {
  .headAction {
    right: 36px;
  }
}

@media only screen and (max-width: 550px) {
  .main-logo {
    overflow: hidden;
    width: 75px;
  }

}

