@import "../Common/Scss/Color";
@mixin btnSize {
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: $white;
}
@mixin btnColor {
  background-color: $red;
}

.headerWrapper {
  box-shadow: 0px -15px 20px 10px $bottomShadow;
  position: fixed;
  z-index: 2;
  width: 100%;

  &.homePage {
    width: 100% !important;
    box-shadow: none;
    border-bottom: 1px solid #ededed;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.43);
    // .headerRightContent {
    //   margin-right: 175px;
    // }
  }

  &.otherPage {
    width: 100%;
    right: 0;

    .headerRightContent {
      // margin-right: 220px;
      @media only screen and (max-width: 1530px) {
        margin-right: 30px;
      }
    }
  }

  .headerContent {
    padding: 5px 0 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logoCover {
      width: 240px;
      overflow: hidden;

      @media only screen and (max-width: 600px) {
        width: 75px;
      }
    }

    .btn {
      margin-right: 15px;
      @include btnSize;
      &.primaryBtn {
        @include btnColor;
      }
    }
  }
}
.customDropdown {
  .userDetails {
    display: grid;
    justify-content: center;
    min-width: 280px;

    .userData {
      display: flex;
      align-items: center;
      justify-content: center;
      .userText {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border: 2px solid #ddd;
        border-radius: 100%;
      }
    }
    .manageBtn {
      border: 2px solid;
      border-radius: 30px;
      padding: 8px 15px;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
.customDialog {
  .MuiDialog-container {
    align-items: inherit !important;
  }
}
