.MuiMenu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .unread {
    background-color: #e1e1e1;
  }
  .notificationWrapper {
    max-width: 375px;
    width: 90vw;
    .heading {
      border-bottom: 1px solid #ddd;
      font-weight: 600;
      padding: 10px 0;
      background: #2769aa;
      color: #fff;
    }
    .notificationContent {
      max-width: 370px;
      margin-bottom: 10px;
      max-height: 65vh;
      .notificationBox {
        padding: 10px;
        display: flex;
        border-bottom: 1px solid #ddd;
        &:hover {
          cursor: pointer;
          background-color: #e3ebff;
        }
        &:last-child {
          border-bottom: 0;
        }
        button {
          padding-top: 0px;
        }
        p {
          font-size: 14px;
          line-height: 18px;
          color: #333333;
          font-weight: 500;
          padding-bottom: 10px;
        }
      }
    }
  }
}
