@import "./_Components/Common/Scss/Color";
@import "./_Components/Common/Scss/font.scss";
@import "./App.css";
@import "./_Components/Common/style.scss";

@mixin btnSize {
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

@mixin btnColor {
  color: $red;
}

.headAction {
  position: absolute;
  right: 10px;
  z-index: 4;
  display: flex;
  align-items: center;
  top: 7px;

  .themeChangeSwitch {
    margin-left: 0;
  }
}

.App {
  &.dark {
    background-color: #1a2b3d;
  }

  &.light {
    background-color: #fffaf5;
  }
}

.mainWrapper {
  min-height: 100vh;
  height: 100%;
  position: relative;

  .homeContentArea {
    padding-top: 65px;
    height: 100%;
    overflow: auto;
    padding-bottom: 68px;
    /*For Mobile responsive*/
    @media screen and (max-width: 600px) {
      padding-bottom: 113px;
    }
    @media screen and (max-width: 425px) {
      padding-bottom: 138px;
    }
  }
  .contentArea {
    padding: 56px 0 20px 0;
    min-height: calc(100vh - 54vh);

    main.dashboardSec {
      margin-top: -50px;
    }

    .btn {
      @include btnSize;
      &.smallPrimaryBtn {
        @include btnColor;
        padding: 5px 10px;
      }
      &.primaryBtn {
        // @include btnColor;
        background-color: #2769aa;
      }
    }

    .customTbl {
      h4.MuiTypography-root {
        font-size: 30px;
        padding: 10px 30px;
        border-bottom: 1px solid #ddd;
      }
    }
  }
}

Dialog {
  .title {
    display: flex;
    align-items: center;
    line-height: normal;
    padding: 5px 10px !important;
  }
}
.linkBtn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #2769aa;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-transform: capitalize;

  &:hover {
    text-decoration: none;
    background-color: rgb(22, 41, 125);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    opacity: 0.8;
  }
}

.MuiDataGrid-filterForm {
  flex-direction: column;
  align-items: flex-start;
}
.MuiDataGrid-filterForm > div:not(:first-child) {
  width: 100%;
}

.MuiDataGrid-main .MuiDataGrid-columnHeaderTitleContainer {
  overflow: visible !important;
  .MuiDataGrid-iconButtonContainer {
    position: absolute;
    right: -12px;
  }
}
