.profileWrapper {
  .subTexts {
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .profileInformation {
    label {
      color: #000;
      font-weight: 600;
      /*For Mobile responsive*/
      @media screen and (max-width: 420px) {
        flex: 1;
        text-align: left;
      }
      /*For Mobile responsive*/
    }
    .heading {
      font-weight: 600;
      /*For Mobile responsive*/
      @media screen and (max-width: 600px) {
        font-size: 1.8rem;
        margin-top: 12px;
      }
      /*For Mobile responsive*/
      .subHeading {
        font-weight: 500;
        font-size: 18px;
      }
    }
    .flex-con-mb {
      display: inline-block;
      /*For Mobile responsive*/
      @media screen and (max-width: 900px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
      /*For Mobile responsive*/
      p.MuiTypography-body1 {
        /*For Mobile responsive*/
        @media screen and (max-width: 420px) {
          flex: 1;
          text-align: left;
        }
        /*For Mobile responsive*/
      }
    }
  }
  .profiler {
    padding: 15px;
    align-items: flex-start;
    .imgBox {
      img {
        border-radius: 10px;
        max-width: 100%;
        border: 1px solid;
        width: 100%;
        object-fit: cover;
      }
    }
    .profileImgSec {
      width: 100%;
      position: relative;
      cursor: pointer;
      font-size: 0;
      /*For Mobile responsive*/
      @media screen and (max-width: 600px) {
        margin: 0 auto;
        max-width: 110px;
      }
      /*For Mobile responsive*/
      &.editingImg {
        img {
          opacity: 0.7;
        }
        .peIcon {
          position: absolute;
          left: 30px;
          font-size: 40px;
          top: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .assigned {
    border: 2px solid #cfdcfd;
    background-color: #dfe8ff;
    position: relative;
    display: flex;
    align-items: start;
    border-radius: 12px;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    padding: 7px 5px;
    .imgSec {
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
    }
    .title {
      line-height: normal;
      font-size: 18px;
      font-weight: 500;
    }
    .subTitle {
      line-height: 20px;
      font-weight: 500;
    }
  }
  .profileInfo {
    .profileData {
      display: grid;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      label {
        font-weight: 600;
        display: block;
        color: #000;
      }
      p {
        word-wrap: break-word;
      }
      .country-cd {
        font-weight: 500;
      }
    }
  }
}
.MuiOutlinedInput-notchedOutline {
  border-color: #ddd !important;
}

.photoFrame {
  position: relative;
  border: 1px dashed;
  border-radius: 15px;
  overflow: hidden;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .custom-file-upload {
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .activeImg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profileImgSec {
    position: absolute;
    top: 0;
  }
  #upload-photo {
    display: none;
  }
}
.baseUrlBtn {
  position: absolute !important;
  right: -2px;
  top: -2px;
  z-index: 1;
  color: #2769aa !important;
  background-color: #d7ebff !important;
  &:hover {
    background-color: #cfdcfd;
  }
}
