.AdFormContainer {
  .studentContainer {
    margin: 10px;
    padding: 10px;
  }
}

.admission_FormWrapper {
  .admission_Form {
    .admission_Card {
      padding: 0px 0 20px;
      margin: 15px 0;
      .mainHead {
        border-bottom: 1px solid #ddd;
        padding: 20px 20px 10px;
      }
      .uploadImg {
        svg {
          cursor: pointer;
          z-index: 9999;
          color: red;
          position: absolute;
          top: 0px;
          background: #fff;
          border-radius: 100%;
          right: 0;
        }
      }
      h6 {
        text-align: left;
        border-bottom: 1px solid #cbc9c9ee;
        margin: 0 0 10px;
        padding: 5px 30px;
        font-weight: 700;
      }
      .admission_FormContent {
        h6 {
          padding-left: 0;
          margin: 0 0 20px 0;
        }
        .customWidth {
          width: 100%;
          margin: 0 0 15px;
        }
        .currentAddress {
          position: relative;
          &:after {
            content: "";
            background: #ccc;
            width: 2px;
            height: 85%;
            display: inline-block;
            position: absolute;
            top: 20px;
            right: -1.4%;
          }
        }
        .MuiGrid-root.MuiGrid-item {
          padding-top: 0 !important;
        }
      }
      .imageUploadSec {
        .imageUpload {
          border: 1px solid;
          border-radius: 4px;
          min-height: 145px;
          max-width: 145px;
          margin: 0 0 0 auto;
          position: relative;
          overflow: hidden;
          /*For Mobile responsive*/
          @media (max-width: 900px) {
            margin: 0 auto 30px;
          }
          /*For Mobile responsive*/
          label {
            height: 100%;
            display: flex;
            text-align: center;
            min-height: 120px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            p {
              margin: 0;
              font-size: 12px;
            }
          }
          .userImg {
            img {
              max-width: 100%;
              position: absolute;
              top: 0;
              transform: translate(0px, 10px);
            }
          }
        }
      }
    }
    .d_flex {
      display: flex;
      align-items: start;
      margin-bottom: 25px;
      .customUpload {
        width: 100%;
        height: 57px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        cursor: pointer;
        &:hover {
          color: #a19d9d;
        }
        label {
          width: 100%;
          height: 100%;
          display: grid;
          align-items: center;
          justify-items: center;
          cursor: pointer;
          span {
            font-size: 11px;
            text-align: center;
            line-height: 10px;
            /*For Mobile responsive*/
            @media (max-width: 1024px) {
              font-size: 8px;
            }
            /*For Mobile responsive*/
          }
        }
        svg {
          font-size: 20px;
          cursor: pointer;
        }
        span {
          cursor: pointer;
        }

        & + .uploadImg {
          position: relative;
          padding-left: 10px;
          img {
            max-height: 57px;
            border-radius: 4px;
          }
          svg {
            position: absolute;
            right: -5px;
            top: -5px;
            color: #ff0000;
            background: #ffffff;
            border-radius: 100%;
          }
        }
      }
    }
    .dNone {
      display: none;
    }
  }
}
.App.light {
  .admission_Form {
    .customUpload {
      label {
        color: #666666 !important;
      }
    }
  }
}
