.react-calendar {
  pointer-events: none;
  width: 100%;
  min-width: auto;
  button {
    background: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  .react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    .react-calendar__navigation__arrow {
      visibility: hidden;
    }
    button {
      cursor: pointer;
      max-width: 200px;
      border-radius: 5px;
      font-weight: 800;
    }
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      box-shadow: 0 0 10px 5px rgba(205, 205, 205, 0.59);
      width: 100%;
      max-width: 30px;
      max-height: 30px;
      border-radius: 8px;
      display: flex;
      align-items: center;
    }
    .react-calendar__navigation__label {
      span {
        font-size: 20px;
        /*For Mobile responsive*/
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
        /*For Mobile responsive*/
      }
    }
  }
  .react-calendar__viewContainer {
    .react-calendar__month-view {
      .react-calendar__month-view__weekdays {
        .react-calendar__month-view__weekdays__weekday {
          text-align: center;
          font-weight: 700;
          /*For Mobile responsive*/
          @media screen and (max-width: 420px) {
            font-size: 12px;
          }
          /*For Mobile responsive*/
          abbr {
            text-decoration: none;
          }
        }
      }
      .react-calendar__month-view__days {
        text-align: center;
        .react-calendar__month-view__days__day {
          position: relative;
          padding: 0;
          min-height: 50px;
          align-items: center;
          abbr {
            font-size: 15px;
            /*For Mobile responsive*/
            @media screen and (max-width: 420px) {
              font-size: 12px;
            }
            /*For Mobile responsive*/
          }
          .custom-tile {
            font-size: 0;
            width: 80%;
            height: 95%;
            border-radius: 10px;
            position: absolute;
            &.Absent:before,
            &.Present:before,
            &.Leave:before,
            &.Half:before {
              content: "";
              position: absolute;
              width: 8px;
              height: 8px;
              border-radius: 100%;
              left: 0;
              right: 0;
              margin: 2px auto 0;
              bottom: 4px;
            }
            &.Absent:before {
              background: #ec1110;
              color: #fff;
            }
            &.Present:before {
              background: #018242;
              color: #fff;
            }
            &.Half:before {
              background: #ff9d00;
              color: #000;
            }
            &.Leave:before {
              background: #5470c6;
              color: #fff;
            }
            &.Absent:after,
            &.Present:after,
            &.Leave:after,
            &.Half:after {
              font-size: 11px;
              color: #585656;
              letter-spacing: 0.2px;
              position: absolute;
              bottom: 10px;
              left: 35px;
            }
          }
        }
        .react-calendar__month-view__days__day--neighboringMonth {
          color: #959ba2;
        }
      }
    }
  }
}

.calValue {
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 700;
  width: 90%;
  display: inline-block;
  margin: 5px 10px !important;
}
.label-tile {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  padding: 5px;
  justify-content: space-between;
  flex-wrap: wrap;

  .label-data {
    position: relative;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.4px;

    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      left: 0px;
      bottom: 8px;
      margin-top: 2px;
      background: var(--status-color); // Dynamic color
    }

    &:after {
      font-size: 11px;
      color: #585656;
      letter-spacing: 0.2px;
      position: absolute;
      bottom: 10px;
      left: 35px;
    }
  }
}

.attendNav {
  width: 40%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin: 0;
  }
}
.customFullNameClass {
  cursor: pointer;
}
