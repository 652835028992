.outerLayer {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  border: 2px solid #cfdcfd;
  background-color: #dfe8ff;
  margin-bottom: 10px;
  &:hover {
    transform: translateY(-5px);
  }
  .tile {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
  }
  .subtitle {
    color: #505050;
  }
}
