.certificateClass {
  padding: 10px;
  margin: 5px;
  .certificateClassDataItem {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    .dataItemBox{
      max-height: 230px;
      overflow: hidden;
      border: 1px solid #ddd;
    }
  }
  .title {
    margin-bottom: 15px;
  }
}
