.myCourseBackgroundColor {
  font-size: 20px;
  &:focus {
    outline: 0 !important;
    border: 1px solid white;
  }
  svg {
    color: white;
  }
}

.linkCourse {
  text-decoration: none;
  color: inherit;
}
nav.adminList {
  background-color: transparent;
  width: 90%;
  .linkCourse {
    min-width: 220px;
    border-radius: 5px;
    // background-color: #2769aa;
    margin-right: 10px;
    overflow: hidden;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      .MuiButtonBase-root {
        background-color: #0081ff;
        .MuiListItemIcon-root{
          svg.MuiSvgIcon-root{
            fill: #fff !important;
          }
        }
        .MuiListItemText-root {
          span {
            font-weight: 700 !important;
            letter-spacing: 0.3px;
            color: #fff !important;
          }
        }

        &:hover {
          background-color: #0081ff;
          .MuiListItemIcon-root{
            svg.MuiSvgIcon-root{
              fill: #fff !important;
            }
          }
        }
      }
    }
    .MuiListItemIcon-root {
      min-width: 36px;
    }
    .classes{
      span{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
.menuOption{
  
  display: flex;
  align-items: flex-start;
  gap:10px;

  @media only screen and (max-width: 950px) {
    display: block;

    nav{
      margin-bottom: 8px;
    }
  }
  nav{
    border-radius: 4px;
    min-width: 200px;
    padding: 0;
    overflow: hidden;
  }
}