/* Times New Roman */
@font-face {
  font-family: 'Times New Roman';
  font-style: normal;
  font-display: swap;
  src: url(TimesNewRoman.ttf);
}

/* Merriweather */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  src: url(Merriweather-Regular.ttf);
}

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-stretch: 100%;
  font-display: swap;
  src: url(OpenSans-Regular.ttf);
}

/* Poppins */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  src: url(Poppins-Regular.ttf);
}
/* Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src: url(Roboto-Regular.ttf);
}

/* Style Script */
@font-face {
  font-family: 'Style Script';
  font-style: normal;
  font-display: swap;
  src: url(StyleScript-Regular.ttf);
}
/* Young Serif */
@font-face {
  font-family: 'Young Serif';
  font-style: normal;
  font-display: swap;
  src: url(YoungSerif-Regular.ttf);
}

@font-face {
  font-family: 'Archivo Black';
  font-style: normal;
  font-display: swap;
  src: url(ArchivoBlack-Regular.ttf);
}



@font-face {
  font-family: "Fredoka", sans-serif;
  font-style: normal;
  font-weight: 700;
  src: url(./Fredoka-Bold.ttf)  format("truetype");
}
@font-face {
  font-family: "Fredoka", sans-serif;
  font-style: normal;
  font-weight: 600;
  src: url(./Fredoka-SemiBold.ttf)  format("truetype");
}
@font-face {
  font-family: "Fredoka", sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(./Fredoka-Medium.ttf)  format("truetype");
}
@font-face {
  font-family: "Fredoka", sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url(./Fredoka-Regular.ttf)  format("truetype");
}
@font-face {
  font-family: "Fredoka", sans-serif;
  font-style: normal;
  font-weight: 300;
  src: url(./Fredoka-Light.ttf)  format("truetype");
}
