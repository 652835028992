.rightWrapperSec {
  display: grid;
  gap: 20px;
  align-content: flex-start;
  .recentActivity,
  .recentAssignment {
    .recentList {
      overflow: auto;
      padding-top: 10px;
    }
  }
}