.colorPicker {
  min-width: 50px;
  min-height: 50px;
  margin-left: 10px;
  .pickerDiv {
    height: 50px !important;
    border: 1px solid rgb(221 221 221) !important;
    background: #ddd;
  }
}
