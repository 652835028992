.principalDashboard {
  .mainCard {
    border-radius: 10px;
    &.attendance {
      background: linear-gradient(47deg, #e1b9aa 0%, #f9c7b3 40%, #fdddd0 100%);
      .listImg {
        background-color: #f8cbb8;
        box-shadow: 4px 1px 8px #fdbea5, 11px -25px 26px #e7c0b2;
      }
    }
    &.notes {
      background: linear-gradient(47deg, #e5ce8f 0%, #ffeab7 40%, #f6e9c4 100%);
      .listImg {
        background-color: #fee9b6;
        box-shadow: 4px 1px 8px #dfcda0, 11px -25px 26px #f0ddae;
      }
    }

    &.report {
      background: linear-gradient(47deg, #a3d9ff 0%, #dbf3ff 40%, #b6d9ed 100%);
      .listImg {
        background-color: #d8f1fd;
        box-shadow: 4px 1px 8px #c9e4f3, 11px -25px 26px #c5e8ff;
      }
    }
    &.certificate {
      background: linear-gradient(47deg, #f9a76b 0%, #f1bf95 40%, #f9ab78 100%);
      .listImg {
        background-color: #ebb78e;
        box-shadow: 4px 1px 8px #c99371, 11px -25px 26px #f3af7e;
      }
    }
  }
}
