.assetsBoxes {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  box-shadow: 2px 2px 10px #ccc;
  min-height: 205px;



  .overlayBox {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 0;
    border-radius: 10px;
    background-color: rgb(0 0 0 / 70%);
    transition: all 0.2s;
    transform: translateY(-50%);



    &.black {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &.red {
      background-color: rgb(163, 50, 11, 0.6);
    }

    &.green {
      background-color: rgba(17, 166, 118, 0.6);
    }

    &.dRed {
      background-color: rgba(220, 63, 59, 0.6);
    }

    .delete {
      position: absolute;
      bottom: 10px;
      right: 10px;
      opacity: 0;
      width: 40px;
      height: 40px;
      z-index: 999999;

      svg {
        width: 40px;
        height: 40px;
        fill: #fff;
      }
    }

    .edit {
      position: absolute;
      bottom: 10px;
      left: 10px;
      opacity: 0;
      width: 40px;
      height: 40px;
      z-index: 999999;

      svg {
        width: 40px;
        height: 40px;
        fill: #fff;
      }
    }

    .topIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      font-weight: bold;
      transition: all 0.2s;
      width: 90%;
      text-align: center;
      transform: translate(-50%, -50%);
      transform-origin: center;
      font-size: 20px;
      opacity: 0;
    }

    .bottomIcon {
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: #000;
      transition: all 0.5s;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 1px;
      width: 100%;
      text-align: center;
    }
  }



  .assetOverlayImg {
    width: 100%;
    max-height: 130px;
    display: block;
    transition: all 0.5s;
    position: relative;
  }




  &:hover .overlayBox {
    background-color: rgb(0 0 0 / 70%);
    height: 100%;
    transform: translateY(-50%);

    .bottomIcon {
      bottom: 20px;
      color: #fff;
    }

    .topIcon {
      transform: translate(-50%, -50%);
      opacity: 1;
      font-size: 25px;
      transform-origin: center;
    }

    .delete,
    .edit {
      opacity: 1;
    }
  }

  .titleFace {
    /* position: absolute;
        top: 10px; */
    // color: #686868;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    min-height: 44px;
  }
}

.courseTitle {
  text-decoration: none;
}

.courseContentAccInner {
  background-image: linear-gradient(#cecece, #fff);
}