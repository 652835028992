//Add Attendance SCSS start here
.attendanceCard {
  min-width: auto;
  .carouselContainer {
    .calendarIcon:after {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      border-left: 1px solid #c0c0c0;
      right: 50px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .carouselContent {
      max-width: 75%;
      margin: 0 auto;
      input[type="radio"] {
        display: none;
      }
      .stdDetails {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        .stdProfile {
          width: 80px;
          border: 1px solid #ccc;
          border-radius: 100%;
          overflow: hidden;
          line-height: 0;
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
      }
      .attendanceOptionBlock {
        .attendanceOption {
          gap: 20px;
          /*For Mobile responsive*/
          @media screen and (max-width: 420px) {
            gap: 15px;
          }
          label {
            margin: 0 20px;
            /*For Mobile responsive*/
            @media screen and (max-width: 768px) {
              margin: 0px;
            }
            &:last-child {
              margin-right: 0;
            }
            &:first-child {
              margin-left: 0;
            }
          }

          .MuiFormGroup-row {
            justify-content: center;
          }
        }
      }

      .select {
        .MuiButtonBase-root {
          display: none;
        }

        .MuiFormControlLabel-label {
          border-radius: 100%;
          padding: 10px 15px;
          font-weight: 600;
          font-size: 20px;
          margin: 10px 0;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--status-color);
          background-color: #ffffff;
          border: 2px solid var(--status-color);
          /*For Mobile responsive*/
          @media screen and (max-width: 768px) {
            width: 30px;
            height: 30px;
            font-size: 15px;
          }
          &:hover {
            background: var(--status-color);
            color: #fff;
            border-radius: 100%;
          }
        }

        .Mui-checked {
          & ~ .MuiFormControlLabel-label {
            background: var(--status-color);
            color: #fff;
            transform: scale(1.3);
          }
        }
        &.selected {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        
      }
    }
  }
}
//Add Attendance SCSS end here

//upload Attendance SCSS start here

.attendanceUpload {
  label {
    border: 1px dashed #c0c0c0;
    border-radius: 5px;
    display: inline-flex;
    width: 100%;
    min-width: auto;
    min-height: 110px;
    align-items: center;
    justify-content: center;
    margin: 8px 0 30px;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;
    svg {
      font-size: 20px;
      margin-bottom: -4px;
    }
  }
}

//upload Attendance SCSS end here
.MuiDatePickerToolbar-root,
.MuiDatePickerToolbar-title {
  display: none;
}
.MuiButtonBase-root {
  &[aria-label="Next"] {
    position: absolute;
    right: 0;
    width: 60px;
    height: 60px;
    box-shadow: 1px -4px 12px -3px rgb(32, 59, 179) inset;
    background-color: transparent;
    &:hover {
      opacity: 1 !important;
      background-color: #ffffff;
      box-shadow: 1px -14px 20px -3px #2769aa inset;
      svg {
        color: #2769aa;
      }
    }
    svg {
      font-size: 40px;
      color: rgba(32, 59, 179, 0.8);
      /*For Mobile responsive*/
      @media screen and (max-width: 768px) {
        font-size: 6vw;
      }
      /*For Mobile responsive*/
    }
    /*For Mobile responsive*/
    @media screen and (max-width: 768px) {
      width: 8vw;
      height: 8vw;
    }
    /*For Mobile responsive*/
  }
  &[aria-label="Previous"] {
    position: absolute;
    left: 0;
    width: 60px;
    height: 60px;
    box-shadow: 1px -4px 12px -3px rgb(32, 59, 179) inset;
    background-color: transparent;
    &:hover {
      opacity: 1 !important;
      background-color: #ffffff;
      box-shadow: 1px -14px 20px -3px #2769aa inset;
      svg {
        color: #2769aa;
      }
    }
    svg {
      font-size: 40px;
      color: rgba(32, 59, 179, 0.8);
      /*For Mobile responsive*/
      @media screen and (max-width: 768px) {
        font-size: 6vw;
      }
      /*For Mobile responsive*/
    }
    /*For Mobile responsive*/
    @media screen and (max-width: 768px) {
      width: 8vw;
      height: 8vw;
    }
    /*For Mobile responsive*/
  }
}
