.recentSec {
    box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 7px 0 rgba(0, 0, 0, 0.18);
    // padding: 20px;
    border-radius: 15px;
    overflow: hidden;
    min-height: 405px;
    @media screen and (max-width: 767px) {
      & {
        margin: 10px 0 20px;
      }
    }
    .recentItemHeader {
      font-size: 20px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
      padding: 10px 20px 10px;
      border-radius: 15px 15px 0 0;
      letter-spacing: 0.08rem;
      color: #fff;
    }
}  

