.showMore {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;

  &-box {
    color: #2769aa;
    cursor: pointer;
  }
}
.attemptUl {
  display: flex;
  flex-direction: row;
  .attemptLi {
    width: calc(100% / 4);
    padding: 10px;
    a {
      padding: 10px;
      display: flex;
      gap: 10px;
      align-items: center;
      text-decoration: none;
      box-shadow: 0 1px 9px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }
}
