.loginForm {
  .loginMssg {
    position: relative;
    top: 7px;
    width: 410px;
    left: -18px;
    p {
      position: absolute;
      text-align: center;
      background: #ffe2e2;
      padding: 5px;
      width: calc(100% - 80px);
      top: -15px;
      border-radius: 6px;
      transform: translate(40px, 0);
      font-weight: 600;
    }
  }

  .loginDetails {
    margin: 30px 0 10px;
    .MuiFormLabel-asterisk{
      display: none;
    }
  }
  .loaderArea {
    background-color: #ddd !important;
    padding:3px;
    span{
      width: 40px !important;
      height: 40px !important;
      color: #fff !important;
    }
  }
  .LoginClose {
    position: absolute !important;
    top: 10px;
    right: 10px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    min-width: auto !important;
    padding: 0 !important;
    z-index: 1;
  }

  .loginFields {
    margin-bottom: 15px;
  }
}
