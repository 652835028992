// Admin dashboard Scss
.adminSec {
  box-shadow: 0 2px 14px #5656577a;
  border-radius: 0 0 15px 15px;
  padding: 0 45px 15px;

  .adminGrid {
    .adminList {
      border: 1px solid #e0ebff;
      //   box-shadow: 0px 4px 34px #e0ebff;
      border-radius: 10px;
      transition: all 1.5s;
      margin-bottom: 24px;
      text-decoration: none;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 15px;
      min-width: 230px;
      padding: 20px;

      .listImg {
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
      }

      .listHead {
        font-size: 18px;
        font-weight: 600;
        color: #1b1b1b;
      }

      &.green {
        // background: #e9f7f1;
        background: linear-gradient(
          47deg,
          #7fe5ba 0%,
          #b4dbc9 40%,
          #daf9eb 100%
        );

        .listImg {
          background: #d0f2e3;

          svg {
            color: #21b477;
          }
        }
      }

      &.orange {
        // background: #fdf2ee;
        background: linear-gradient(
          47deg,
          #e1b9aa 0%,
          #f9c7b3 40%,
          #fdddd0 100%
        );

        .listImg {
          background: #fde8e0;

          svg {
            color: #ff602e;
          }
        }
      }

      &.yellow {
        // background: #fff8e5;
        background: linear-gradient(
          47deg,
          #e5ce8f 0%,
          #ffeab7 40%,
          #f6e9c4 100%
        );

        .listImg {
          background: #f8eed4;

          svg {
            color: #ffb501;
          }
        }
      }

      &.purple {
        // background: #ebeaf2;
        background: linear-gradient(
          47deg,
          #9d98bf 0%,
          #c4c9ec 40%,
          #c2c5dd 100%
        );

        .listImg {
          background: #cfced9;

          svg {
            color: #392c7d;
          }
        }
      }
    }
  }
}

.adminInSec {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;

  .adminInList {
    text-decoration: none;
    width: calc(95% / 2);
    display: inline-block;
    padding: 10px 20px;
    border-radius: 7px;
    text-align: center;
    box-shadow: 0 2px 12px 4px rgba(0, 0, 0, 0.2);

    .listHead span {
      color: #101010;
      font-weight: 600;
    }
  }
}

//new dashboard changes as per 27-09-2023
.dashboardContainer {
  .dashboardFrame {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 361px;
    gap: 24px;
  }
  .dashboardFrame2 {
    grid-template-columns: max-content;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .dashboardContainer {
    .dashboardFrame {
      grid-template-columns: 1fr;
    }
  }
}
