.modalContent {
  display: flex;
  align-items: start;
  svg {
    font-size: 18px;
  }
  p {
    margin: 0;
    margin-left: 5px;
    text-transform: capitalize;
    &.textTransform{
        text-transform: uppercase;
    }
  }
  
  b {
    font-size: 16px;
    font-weight: 600;
  }
}
